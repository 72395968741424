<template>
  <div class="city-add main-block col-lg-6">
    <form action="" @submit.prevent="save()">
      <div class="city-add__box main-block__head">
        <h1 class="city-add__box-title main-block__title">Информация</h1>
        <button class="main-block__btn default-btn" type="submit">Сохранить</button>
      </div>
      <ul class="city-add__items">
        <li class="city-add__item flex">
          <p class="city-add__key">Название</p>
          <input
              v-model="title"
              type="text"
              class="city-add__value city-add__value_align"
              placeholder=""
              required
          />
        </li>
        <li v-if="citiesProducts" class="city-add__item flex">
          <p class="city-add__key">Город выезда</p>
          <b-form-select
              v-model="countryId"
              class="city-add__value main__select"
              :options="filterCitiesProducts"
              text-field="name"
              value-field="id"
          />
        </li>
        <li class="city-add__item item-multiselect flex">
          <p class="city-add__key">Выбор города</p>
          <multiselect
              v-model="cityId"
              class="city-add__multiselect"
              :options="filterCitiesProducts"
              label="name"
              track-by="id"
              :multiple="true"
              placeholder="Выбор города"
          />
        </li>
        <li class="city-add__item flex">
          <p class="city-add__key">Выезд по графику</p>
          <b-form-checkbox v-model="dateChange" class="city-add__checkbox" />
        </li>
        <li class="city-add__item flex">
          <p class="city-add__key">Периодичность</p>
          <b-form-select
              v-model="periodicity"
              class="city-add__value main__select"
              :options="filterPeriodicity"
              text-field="name"
              value-field="id"
          />
        </li>
        <li v-if="dateChange" class="city-add__item flex">
          <p class="city-add__key">Дата выезда</p>
          <div class="city-add__week flex">
            <div
                v-for="(item, index) in weekList"
                :key="index"
                class="city-add__week-item"
                :class="{
                'city-add__week-active': weekList[index].type === true,
              }"
                @click="weekAdd(index)"
            >
              {{ item.week }}
            </div>
          </div>
        </li>
        <li v-if="dateChange" class="city-add__item flex">
          <p class="city-add__key">Время выезда</p>
          <div class="city-add__week flex">
            <div
                v-for="(item, index) in weekList"
                :key="index"
                class="city-add__week-item time-input"
                :class="{ 'city-add__week-input': weekList[index].type === true }"
            >
              <input
                  v-if="weekList[index].type"
                  v-model="item.time"
                  type="time"
                  class="city-add__input"
                  placeholder=""
                  required
                  @change="weekAdd(index, item.time)"
              />
            </div>
          </div>
        </li>
        <li class="city-add__item flex">
          <p class="city-add__key">Выбор контрагента</p>
          <b-form-checkbox v-model="contragentShow" class="city-add__checkbox" />
        </li>
        <li v-if="contragentsList && contragentShow" class="city-add__item item-multiselect flex">
          <p class="city-add__key">Выбор контрагента</p>
          <multiselect
              v-model="contragentsId"
              class="city-add__multiselect"
              :options="filterContragentsList"
              label="name"
              track-by="id"
              :multiple="true"
          />
        </li>
        <li class="city-add__item flex">
          <p class="city-add__key">Выбор водителя</p>
          <b-form-checkbox v-model="driverShow" class="city-add__checkbox" />
        </li>
        <li v-if="driversListRegion && driverShow" class="city-add__item item-multiselect flex">
          <p class="city-add__key">Выбор водителя</p>
          <multiselect
              v-model="driversId"
              class="city-add__multiselect"
              :options="filterDriversListRegion"
              label="name"
              track-by="id"
              :multiple="true"
          />
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: "",
      countryId: "",
      cityId: null,
      contragentsId: [],
      driversId: [],
      date: "",
      time: "",
      periodicity: null,
      dateChange: false,
      contragentShow: false,
      driverShow: false,
      weekList: [
        { week: "Пн", type: false, time: null, value: "isMonday" },
        { week: "Вт", type: false, time: null, value: "isTuesday" },
        { week: "Ср", type: false, time: null, value: "isWednesday" },
        { week: "Чт", type: false, time: null, value: "isThursday" },
        { week: "Пт", type: false, time: null, value: "isFriday" },
        { week: "Сб", type: false, time: null, value: "isSaturday" },
        { week: "Вс", type: false, time: null, value: "isSunday" },
      ],
      // options: [
      //   { name: 'Almaty', id: 'vu' },
      //   { name: 'Astana', id: 'js' },
      //   { name: 'Shymkent', id: 'os' }
      // ]
    };
  },
  computed: {
    ...mapState(["regionList", "citiesProducts", "countriesList", "contragentsList", "periodList", "driversListRegion"]),
    filterContragentsList() {
      if (this.contragentsList && this.contragentsList.length > 0) {
        return [
          {
            id: null,
            name: "Выбор контрагента",
          },
          ...this.contragentsList,
        ];
      }
      return [{ id: null, name: "Выбор контрагента" }];
    },
    filterDriversListRegion() {
      if (this.driversListRegion && this.driversListRegion.length > 0) {
        return [
          {
            id: null,
            name: "Выбор водителя",
          },
          ...this.driversListRegion,
        ];
      }
      return [{ id: null, name: "Выбор водителя" }];
    },
    filterCitiesProducts() {
      if (this.citiesProducts && this.citiesProducts.length > 0) {
        return [...this.citiesProducts];
      }
      return [{ id: null, name: "Выберите город" }];
    },
    filterPeriodicity() {
      if (this.periodList && this.periodList.length > 0) {
        return [...this.periodList];
      }
      return [{ id: null, name: "Выберите периодичность" }];
    },
  },
  watch: {
    contragentShow() {
      if (!this.contragentShow) {
        this.contragentsId = null;
      }
    },
    driverShow() {
      if (!this.driverShow) {
        this.driversId = null;
      }
    },
  },
  async created() {
    if (this.$route.query.id) {
      await this.$store.dispatch("getRegionList");
      await this.$store.dispatch("getDriversListRegion");
      await this.$store.dispatch("getContragentsList");
      await this.$store.dispatch("getPeriodList");
      const region = this.regionList?.data?.find((e) => e.id === +this.$route.query.id);
      if (region) {
        this.title = region.title;
        this.cityId = region.cities;
        if (region.contragents?.length) {
          this.contragentShow = true;
        }
        if (region.driversRegion?.length) {
          this.driverShow = true;
        }
        this.contragentsId = this.contragentsList.filter((e) =>
            region.contragents.some((contragent) => e.id === contragent)
        );
        this.driversId = this.driversListRegion.filter((e) =>
            region.driversRegion.some((driver) => e.id === driver)
        );
        if (region.country_id === "Нур-Султан") {
          this.countryId = 247;
        } else {
          this.countryId = 246;
        }
        this.date = region.details?.date;
        this.time = region.details?.time?.slice(0, 5);
        this.dateChange = !!region.schedule?.length;
        if (this.dateChange) {
          this.weekList.map((week) => {
            let obj = region.schedule.find((e) => e.day === week.value);
            if (obj) {
              week.type = true;
              week.time = obj.time?.slice(0, 5);
              if (obj.periodicity) {
                this.periodicity = obj.periodicity
              }
            }
          });
        }
      }
    }
    await this.$store.dispatch("getCountriesList");
    await this.$store.dispatch("getCitiesProducts");
    await this.$store.dispatch("getPeriodList");
    // this.countryId = this.citiesProducts.find((city) => city.name === "Шымкент")?.id;
  },
  methods: {
    weekAdd(index, time = null) {
      if (time === null) {
        this.weekList[index].type = !this.weekList[index].type;
      }
      if (time !== null) this.weekList[index].time = time;
    },
    addTransport() {
      this.transports.push({ id: null });
    },
    removeTransport() {
      this.transports.pop({ id: null });
    },
    save() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("region_id", this.$route.query.id);
      formData.append("country_id", this.countryId);
      formData.append("periodicity", this.periodicity);
      this.cityId.forEach((e, i) => {
        formData.append(`cities[${i}]`, e.id);
      });
      this.contragentsId.forEach((e, i) => {
        formData.append(`contragents[${i}]`, e.id);
      });
      this.driversId.forEach((e, i) => {
        formData.append(`drivers[${i}]`, e.id);
      });
      this.weekList.forEach((e, index) => {
        if (e.type) {
          formData.append(`days[${index}]`, e.value);
          if (e.time) {
            formData.append(`times[${index}]`, `${e.time}:00`);
          }
        }
      });
      this.$api
          .post(this.$route.query.id ? "/web/edit-region" : "/web/add-region", formData)
          .then(() => {
            this.$router.push("/database/regions");
          })
          .catch((e) => {
            this.$toast.error(e.response?.data?.message);
          });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.item-multiselect:deep(.multiselect__tag) {
  background-color: var(--secondary-color);
}
.item-multiselect:deep(.multiselect__option--highlight) {
  background-color: var(--secondary-color);
}
.item-multiselect:deep(.multiselect__option--highlight::after) {
  background-color: var(--secondary-color);
}
.item-multiselect :deep(.multiselect__option--highlight.multiselect__option--selected) {
  background-color: var(--tertiary-color);
}
.item-multiselect :deep(.multiselect__option--highlight.multiselect__option--selected::after) {
  background-color: var(--tertiary-color);
}
.city-add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.city-add__item {
  padding-top: 11.5px;
  min-height: 27.5px;
  position: relative;
}

.city-add__item:not(:last-child) {
  padding-bottom: 11.5px;
  min-height: 39px;
  height: auto;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.city-add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.city-add__value {
  width: 50%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}

.city-add__value_align {
  text-align: end;
}
.city-add__week-item {
  width: 70px;
  height: 30px;
  /*padding: 10px 10px;*/
  box-sizing: border-box;
  background-color: #d8e7ec;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.city-add__week-item:not(:last-child) {
  margin-right: 5px;
}
.city-add__week-active,
.city-add__week-item:hover {
  background-color: #96dfea;
}
.time-input {
  opacity: 0;
}
.city-add__week-input {
  opacity: 1;
}
.city-add__input {
  width: 90%;
  height: 80%;
  padding: 0 0 0 2px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.item-multiselect {
  margin: 18px 0 12px 0;
}
.city-add__multiselect {
  width: 50%;
}
</style>
